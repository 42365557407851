import React from "react";
import PageSection from "../../components/pageSection";
import Link from "../../../link";

const ContactBoardBCB = () => (
  <PageSection title="Bestuur contacteren?">
    <p>
      Het bestuur van BCB bestaat uit een voorzitter en 4 bestuursleden, te
      weten:
    </p>
    <ul>
      <li>
        Dhr. J.P.H. Siemensma (voorzitter),{" "}
        <Link href="mailto:bcb.bestuur@gmail.com?subject=voorzitter">
          {" "}
          bcb.bestuur@gmail.com{" "}
        </Link>{" "}
      </li>
      <li>
        Mevr. D.V.L. Van Agt - Jansen (secretaris),{" "}
        <Link href="mailto:bcb.secretaris@gmail.com?subject=secretaris">
          bcb.secretaris@gmail.com
        </Link>{" "}
      </li>
      <li>
        Mevr. G.W.M. Robijns – Buck (penningmeester),{" "}
        <Link href="mailto:bcb.penningmeester@gmail.com?subject=penningmeester">
          bcb.penningmeester@gmail.com
        </Link>{" "}
      </li>
      <li>
        Dhr. C.M.C. Robijns,{" "}
        <Link href="mailto:bcb.bestuur@gmail.com">
          {" "}
          bcb.bestuur@gmail.com{" "}
        </Link>
      </li>
      <li>
        Mevr. I.J.R. Siemensma,{" "}
        <Link href="mailto:bcb.bestuur@gmail.com">
          {" "}
          bcb.bestuur@gmail.com{" "}
        </Link>
      </li>
      <li>
        Dhr. T. van Agt,{" "}
        <Link href="mailto:bcb.bestuur@gmail.com">
          {" "}
          bcb.bestuur@gmail.com{" "}
        </Link>
      </li>
    </ul>
    <p>
      Door op de oranje link te klikken, wordt er een standaardmail geopend
      waarmee u het betreffende bestuurslid kunt bereiken. Vragen met betrekking
      tot automatisering, website of actuariële vragen kunt u sturen naar{" "}
      <Link href="mailto:bcb.technisch@gmail.com?subject=automatisering/processen">
        bcb.technisch@gmail.com
      </Link>
    </p>
    <p>Op dit moment zijn wij nog op zoek naar aspirant bestuursleden!</p>
  </PageSection>
);

export default ContactBoardBCB;
