// @flow
import React, { Component } from "react";
import BCBLogo from "../../../images/BCB-logo.png";
import Graan from "../../../images/graan.jpg";
import Divider from "material-ui/Divider";
import { withRouter, WithRouterProps } from "react-router";
import Header from "../../ledenPortaal/header/homeHeader";
import { colorsBCV } from "../../../styling";
import Contribution from "../contribution";
import { FloatingFooter } from "../../../components/footer";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import Scroll from "react-scroll";
import {
  LoginButton,
  PackageInfoButton,
  BecomeMemberButton,
  ContactInfoButton,
} from "./components";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    height: "100%",
    width: "100%",
  },
  topContainer: {
    height: 700,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(" + Graan + ")",
    backgroundSize: "100% 140%",
  },
  dimmer: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  middleContainer: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  bottomContainer: {
    width: "20%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    margin: "10%",
  },
  logo: {
    width: "170px",
    height: "120px",
    paddingLeft: "40px",
  },
};

class Home extends Component<WithRouterProps> {
  render() {
    return (
      <div style={styles.container}>
        <Header />
        <FloatingFooter />
        <div style={styles.topContainer}>
          <div style={styles.dimmer}>
            <div
              style={{
                paddingTop: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img style={styles.logo} src={BCBLogo} alt="" />
              <h1 style={{ color: "white", marginBottom: 0 }}>BCB Kerkrade</h1>
              <h2 style={{ color: "white", marginTop: 10 }}>
                Begrafenis- en Crematievereniging Bleijerheide
              </h2>

              <div style={{ paddingTop: "30px" }}>
                <LoginButton />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "-28px",
          }}
        >
          <FloatingActionButton
            onClick={() => {
              Scroll.animateScroll.scrollTo(500);
            }}
          >
            <ArrowDown />
          </FloatingActionButton>
        </div>

        <div
          style={{
            marginTop: "50px",
            marginBottom: "100px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ ...styles.middleContainer }}>
            <h2 style={{ color: colorsBCV.secondary }}>
              Algemene Ledenvergadering 13 Mei 2025
            </h2>
            <div style={{ maxWidth: "80%" }}>
              <p>
                Op dinsdag 13 mei 2025 wordt de algemene ledenvergadering (ALV)
                gehouden, waarvoor wij u graag uitnodigen.
              </p>
              Deze wordt gehouden in{" "}
              <b>
                't Sjtaaterhoes, Schaesbergerweg 27 te Kerkrade om 20:00 uur.
              </b>
              <p>
                De accountant maakt de jaarrekening en vervolgens vindt er
                kascontrole plaats door de kascontrolecommissie. Wij hebben 3
                leden bereid gevonden om zitting te nemen in de
                kascontrolecommissie voor boekjaar 2024. Op deze algemene
                ledenvergadering zal de kascontrolecommissie haar advies geven
                aan de leden, waarop vervolgens decharge verleend kan worden aan
                het bestuur.
              </p>
              <p>
                {" "}
                <b>De agenda van de ALV ziet als volgt uit:</b>
                <ul>
                  <li>Opening & 1 minuut stilte voor de overleden leden</li>
                  <li>Goedkeuring van de notulen van de ALV dd. 7 mei 2024 & dd. 9 oktober 2024</li>
                  <li>Verslag accountant</li>
                  <li>
                    Verslag door de kascontrolecommissie over het boekjaar 2024
                  </li>
                  <li>Rapportage financiën</li>
                  <li>Contributieverhoging 2026</li>
                  <li>Rooster van aftreden</li>
                  <li>Mededelingen & bespreken eventuele ledenvoorstellen</li>
                  <li>Rondvraag</li>
                  <li>Sluiting</li>
                </ul>
              </p>
              <p>
                Wij zien u graag tijdens de algemene jaarvergadering op 13 mei
                in 't Sjtaaterhoes in Kerkrade.
              </p>
            </div>
          </div>
          <Divider />
          <div style={{ ...styles.middleContainer, height: 100 }}>
            <h2 style={{ color: colorsBCV.secondary }}>Onze vereniging</h2>
            <div style={{ maxWidth: "80%" }}>
              Het doel van de vereniging is om op basis van vrijwilligheid,
              onderlinge solidariteit en nabuurschap en/of geïnspireerd vanuit
              een christelijke visie, onderlinge hulp te verlenen op het gebied
              van uitvaart en een overledene op de meest waardige wijze,
              overeenkomstig zijn/haar wensen, naar zijn/haar laatste rustplaats
              te brengen, mede om te voorkomen dat de verzorging van een
              stoffelijk overschot van de mens een voorwerp van winstbejag
              wordt.
            </div>
          </div>
        </div>
        <Divider />
        <section
          style={{
            width: "70%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: "auto",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <h2 style={{ color: colorsBCV.secondary }}>Kosten lidmaatschap</h2>
          <div>
            Vul uw geboortedatum in en bereken direct wat de contributie van een
            lidmaatschap bij BCB is.
          </div>
          <div style={{ paddingTop: "30px" }} />
          <Contribution />
        </section>
        <Divider />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <div style={styles.bottomContainer}>
            <div style={{ fontWeight: "bold" }}>Pakketten</div>
            <div
              style={{
                paddingTop: "15px",
                paddingBottom: "40px",
                height: "60px",
                width: "100%",
              }}
            >
              Bij BCB bestaan drie naturavergoedingen.
            </div>
            <PackageInfoButton />
          </div>
          <div style={styles.bottomContainer}>
            <div style={{ fontWeight: "bold" }}>Lidmaatschap</div>
            <div
              style={{
                paddingTop: "15px",
                paddingBottom: "40px",
                height: "60px",
                width: "100%",
              }}
            >
              Als lid van BCB bent u verzekerd van een waardige uitvaart.
            </div>
            <BecomeMemberButton />
          </div>
          <div style={styles.bottomContainer}>
            <div style={{ fontWeight: "bold" }}>Contact</div>
            <div
              style={{
                paddingTop: "15px",
                paddingBottom: "40px",
                height: "60px",
                width: "100%",
              }}
            >
              Neemt u gerust contact op met onze secretaris.
            </div>
            <ContactInfoButton />
          </div>
        </div>
        <Divider />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              ...styles.middleContainer,
              marginTop: "50px",
              marginBottom: "50px",
              width: "60%",
            }}
          >
            <h2 style={{ color: colorsBCV.secondary }}>Overlijden</h2>
            <div style={{ paddingBottom: "40px" }}>
              U hoeft het overlijden bij ons niet te melden. Uw
              uitvaartondernemer dient dit aan ons door te geven. U mag zelf een
              uitvaartondernemer kiezen.
              <br />
              <br />
              De kosten van de uitvaart worden vergoed ter hoogte van de
              pakketvergoeding behorend bij pakket A, B of C. Bespreek dit goed
              met uw uitvaartondernemer, zodat u niet achteraf voor vervelende
              financiële verrassingen komt te staan.
              <br />
              <br />
              Wij hebben een samenwerkingsverband met{" "}
              <b>
                <a href="https://www.mok-uitvaart.nl">Mok Uitvaartverzorging</a>
              </b>{" "}
              te Heerlen en{" "}
              <b>
                <a href="https://www.paffenuitvaartverzorging.nl">
                  Uitvaartverzorging Paffen
                </a>
              </b>{" "}
              te Kerkrade.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DecoratedHome = withRouter(Home);

export default DecoratedHome;
